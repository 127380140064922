import passiveIfPossible from "../helpers/passive-if-possible";
export default function topMenu() {
  let header = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document.getElementById("header");
  let hasWrapper = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let element = header && header.getElementsByClassName("top-menu")[0];
  if (!element) return;
  const links = element.getElementsByTagName("a");
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  element.addEventListener("click", trackMenuItemClick, passiveIfPossible);
  if (hasWrapper) element = element.firstElementChild;
  element.addEventListener("scroll", trackShowMore, {
    passive: true,
    once: true
  });
  function trackMenuItemClick(event) {
    const clickTarget = event.target.closest("a");
    if (!clickTarget) return;
    const index = Array.prototype.indexOf.call(links, clickTarget);
    if (!index) return;
    const targetLabel = clickTarget.innerText.trim().toLowerCase();
    window.dataLayer.push({
      event: "navigation link click",
      eventParams: {
        category: "navigation"
      },
      engagement: {
        type: "link click",
        component: {
          position: "top of page",
          name: targetLabel,
          index: `${index - 1}`,
          type: "top menu"
        },
        destination: {
          url: links[index].href
        }
      }
    });
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("navigation", "click", `context_menu|${targetLabel}|${index - 1}`);
    });
  }
  function trackShowMore(event) {
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("navigation", event.type, "context_menu|more");
    });
  }
}