const useObserver = ("IntersectionObserver" in window);
import { checkConsent } from "../integration/didomi";
import outsiderAds from "./outsiderAds";
import scrollDepth from "./scrollDepth";
import tracking from "./tracking";
const observers = new Map();
function init(article) {
  if (!article || !useObserver) return;
  const viewportHeight = window.innerHeight;
  remove(article);
  observers.set(article.dataset.title, getArticleObserver());
  checkConsent("analytics", "c:bonniernews", consentGiven => {
    if (!consentGiven) return;
    observers.set(article.dataset.title, getScrollDepthObserver());
  });
  function getArticleObserver() {
    const options = {
      rootMargin: `${article.offsetHeight}px 0px 0px 0px`,
      threshold: [...Array(101).keys()].map(value => value * 0.01)
    };
    if (window.CHANNEL_DESKTOP) {
      outsiderAds.init(article);
    }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const percentageScrolled = Math.round(-entry.boundingClientRect.top / (entry.boundingClientRect.height - viewportHeight) * 100);
        if (window.CHANNEL_DESKTOP) {
          outsiderAds.update(article, percentageScrolled);
        }
      });
    }, options);
    observer.observe(article);
    return observer;
  }
  function getScrollDepthObserver() {
    const options = {
      rootMargin: `${article.offsetHeight}px 0px 0px 0px`,
      threshold: [0.25, 0.5, 0.75, 1]
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const percentageScrolled = entry.intersectionRatio * 100;
        if (percentageScrolled === 100) {
          tracking.trackCustomActive("action", "scroll", "article foot");
        }
        if (window.Exp.config.hasTopDomain || article.classList.contains("branded-article")) {
          scrollDepth.init(article, percentageScrolled);
        }
      });
    }, options);
    observer.observe(article);
    return observer;
  }
}
function remove(article) {
  const articleTitle = article.dataset.title;
  if (observers.has(articleTitle)) {
    const observer = observers.get(articleTitle);
    observer.unobserve(article);
    observers.delete(articleTitle);
  }
}
export default {
  init,
  remove
};