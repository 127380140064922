let shouldUpdateAd, hasUpdatedAd, adContainer;
function init(container) {
  let minHeight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4000;
  if (!container || window.innerWidth < 1600) return;
  trim(container);
  adContainer = [...container.getElementsByClassName("outsider-ads__ad-slot")].pop();
  if (!adContainer) return;
  const {
    height
  } = container.getBoundingClientRect();
  shouldUpdateAd = height >= minHeight;
  if (!shouldUpdateAd) return;
  hasUpdatedAd = false;
}
function update(container, percentageScrolled) {
  let minDepth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 70;
  if (!container || window.innerWidth < 1600) return;
  if (shouldUpdateAd && percentageScrolled >= minDepth && percentageScrolled <= minDepth + 15 && !hasUpdatedAd) {
    hasUpdatedAd = true;
    replaceAd(adContainer);
  }
}
function replaceAd(adSlot) {
  if (!adSlot || !adSlot.connectedCallback) return;
  adSlot.connectedCallback();
}
function trim(container) {
  const outsiderContainer = container && container.getElementsByClassName("outsider-ads")[0];
  if (!outsiderContainer) return;
  const offsetTop = outsiderContainer.offsetTop;
  const bodySiteHeight = container.offsetHeight - offsetTop;
  const outsiderTwo = container.getElementsByClassName("outsider-2")[0];
  const outsiderThree = container.getElementsByClassName("outsider-3")[0];
  if (bodySiteHeight > 2000) {
    outsiderTwo.classList.remove("hidden");
    outsiderThree?.classList.remove("hidden");
    return;
  }
  if (bodySiteHeight < 1400) {
    if (offsetTop > container.offsetHeight) {
      outsiderContainer.style.top = `${bodySiteHeight / 2}px`;
    }
    return;
  }
  outsiderTwo.classList.remove("hidden");
  if (bodySiteHeight < 2500) {
    if (offsetTop > container.offsetHeight) {
      outsiderContainer.style.top = `${bodySiteHeight / 2}px`;
    }
    return;
  }
  if (outsiderThree) outsiderThree.classList.remove("hidden");
}
export default {
  init,
  update,
  trim
};