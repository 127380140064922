import "regenerator-runtime/runtime"; // Needed for async/await functionality
import accountMenu from "./plugins/accountMenu";
import article from "./plugins/article";
import bindShowMoreButton from "./plugins/searchShowMore";
import carouselController from "./plugins/carouselController";
import { checkConsent } from "./integration/didomi";
import colorScheme from "./plugins/colorScheme";
import continuous from "./plugins/continuous";
import facebookPixelProxy from "@exp/scripts/plugins/facebook-pixel-proxy";
import factbox from "./plugins/factbox";
import gtm from "@exp/scripts/integration/gtm";
import iframeSync from "./plugins/iframeSync";
import initPrintButton from "./plugins/initPrintButton";
import lazyLoadStylesheets from "./plugins/lazyLoadStylesheets";
import lazyLoadWidgets from "./plugins/lazyLoadWidgets";
import pageObserver from "./plugins/pageObserver";
import recipeCheckbox from "./plugins/recipeCheckbox";
import recipeIngredientCount from "./plugins/recipeIngredientCount";
import recipeRating from "./plugins/recipe-rating";
import searchBySections from "./plugins/searchBySections";
import searchWidget from "./plugins/header/searchWidget";
import sectionShowMore from "./plugins/sectionShowMore";
import siteHeader from "./plugins/siteHeader";
import siteMenu from "@exp/components/site-menu/site-menu.mjs";
import textShowMore from "./plugins/textShowMore";
import toggleConsentNotice from "./plugins/toggleConsentNotice";
import trackPageUnfreeze from "./helpers/trackPageUnfreeze";
import tracking from "./plugins/tracking";
import truncate from "./plugins/truncate";
import videoPlayer from "./plugins/videoPlayer";
import webVitals from "@exp/scripts/integration/web-vitals";
gtm();
trackPageUnfreeze();
lazyLoadStylesheets("lazy-stylesheet");
if (!window.Exp.isHybridTablet) {
  siteHeader();
  siteMenu();
  accountMenu();
}
checkConsent("analytics", "c:bonniernews", consentGiven => {
  if (!consentGiven) return;
  tracking.init({
    container: document,
    trackSelector: "js-ct"
  });
});
const articleContainer = document.getElementsByClassName("js-article")[0];
let observer;
if (articleContainer) {
  observer = pageObserver("article");
  article(articleContainer, false, observer);
  continuous(articleContainer, observer);
  recipeCheckbox(document);
  recipeIngredientCount(document);
  textShowMore(document);
  recipeRating(document);
  initPrintButton(document);
} else {
  const sectionContainer = document.getElementsByClassName("section")[0];
  observer = pageObserver("section");
  videoPlayer();
  bindShowMoreButton(document);
  carouselController("blogs");
  lazyLoadWidgets({
    selectorClass: "widget-area",
    scope: document
  });
  truncate(document);
  factbox(document);
  if (sectionContainer) {
    observer.observe(sectionContainer);
  }
  sectionShowMore(document);
}
iframeSync();
searchBySections();
toggleConsentNotice();
if (window.Exp.config.hasSearchWidget) {
  searchWidget(document);
}
webVitals();
facebookPixelProxy();
colorScheme();