import sectionShowMore from "./sectionShowMore";
import tracking from "./tracking";
export default function bindSearchBySectionsSelect() {
  const sectionFilterSelectElement = document.getElementsByClassName("section-filter__select")[0];
  if (!sectionFilterSelectElement) return;
  sectionFilterSelectElement.addEventListener("change", onChangeSelect);
  function onChangeSelect(e) {
    const currentSection = window.location.pathname;
    const selectedSection = e.target.value;
    const selectedSectionName = e.target.options[e.target.selectedIndex].text;
    const historyState = selectedSection ? `?section=${selectedSection}` : window.location.pathname;
    window.history.replaceState(null, null, historyState);
    const sections = [currentSection, selectedSection].filter(Boolean).join(",");
    const query = `/search-by-sections/?sections=${encodeURIComponent(sections)}`;
    const path = window.Exp.config.hasTopDomain ? query : `${window.Exp.config.path}${query}`;
    window.fetch(path, {
      credentials: "same-origin"
    }).then(res => {
      return res.text();
    }).then(markup => {
      if (!markup || markup.length <= 1) {
        return;
      }
      const teaserList = document.getElementsByClassName("section-teasers")[0];
      const noHit = document.getElementsByClassName("nohit-wrapper")[0];
      let trackingValue;
      if (teaserList) {
        teaserList.innerHTML = markup;
        if (noHit) {
          trackingValue = `filter_articles|${currentSection}`;
          tracking.trackCustomActive("click", "no_articles", trackingValue);
        } else {
          trackingValue = `filter_articles|${currentSection}`;
          tracking.trackCustomActive("click", `${tracking.getTrackValue(selectedSectionName)}`, trackingValue);
        }
      }
      sectionShowMore(document);
    });
  }
}